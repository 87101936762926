import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en/en';
import de from './de/de';
import dateFormats from './dateFormat';
const dateTimeFormats = dateFormats;

Vue.use(VueI18n);

const locale = navigator.language;

const messages = {
  'en-GB': en,
  en: en,
  de: de,
  'de-DE': de,
};

const i18n = new VueI18n({
  locale,
  dateTimeFormats,
  messages,
  fallbackLocale: 'en-GB',
  silentTranslationWarn: true,
});

export default i18n;
