import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes' });

export function getIdToken() {
  return ls.get('idToken');
}
export function setIdToken(val) {
  ls.set('idToken', val);
}
export function removeIdToken() {
  ls.remove('idToken');
}
