/*
  Used to deal with maintaining state for logged in users
*/
import copy from 'fast-copy';
import SecureLS from 'secure-ls';
import configData from '@/config/config.json';
import router from '@/router/index';
import { ref, getDatabase, get, child } from 'firebase/database';
import {
  getAuth,
  signInWithEmailAndPassword,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
const firebaseUserUrl = configData.customer.firebaseUserUrl;
const firebaseProductsUrl = configData.customer.firebaseProductsUrl;
const ls = new SecureLS({ encodingType: 'aes' });

export default {
  namespaced: true,
  state: {
    userId: null,
    user: null,
    email: null,
    users: [],
    products: [],
    validated: false,
    selectedTab: 0,
  },
  mutations: {
    SET_SELECTED_TAB(state, tab) {
      state.selectedTab = tab;
    },
    SET_VALIDATED(state, validated) {
      state.validated = validated;
    },
    ADD_PRODUCT(state, product) {
      // check if we've already loaded the product, if so update it
      for (let i = 0; i < state.products.length; i++) {
        if (state.products[i].product === product.product) {
          state.products[i] = product;
          return;
        }
      }
      // We got here because the product didn't exist so add it
      state.products.push(product);
    },
    DISCARD_PRODUCTS(state) {
      while (state.products.length > 0) {
        state.products.pop();
      }
    },
    AUTH_USER(state, userData) {
      state.userId = userData.userId;
      state.email = userData.email;
    },
    STORE_USER_DATA(state, user) {
      state.user = user;
    },
    CLEAR_AUTH_DATA(state) {
      ls.remove('idToken');
      state.userId = null;
      state.user = null;
      state.email = null;
      state.selectedAppointment = null;
      state.appointments = {};
    },
    STORE_USER: (state, remoteUser) => {
      // Search for an existing user with same Id and replace
      const users = copy(state.users);
      let existing = false;
      for (let i = 0; i < users.length; i++) {
        if (users[i].key === remoteUser.key) {
          users.splice(i, 1, remoteUser);
          existing = true;
          break;
        }
      }
      // New user to add to the array
      if (!existing) {
        users.push(remoteUser);
      }
      state.users = users;
    },
    DISCARD_USERS: (state) => {
      while (state.users.length > 0) {
        state.users.pop();
      }
    },
  },
  actions: {
    setSelectedTab({ commit }, tab) {
      commit('SET_SELECTED_TAB', tab);
    },
    async getProducts({ commit }) {
      try {
        const db = getDatabase(this.$firebase);

        const dbRef = ref(db);
        const snapshot = await get(child(dbRef, `${firebaseProductsUrl}`));
        if (snapshot.exists()) {
          const productResults = snapshot.val();
          const keys = Object.keys(productResults);
          keys.forEach((key) => {
            let product = {
              product: key,
              enabled: productResults[key],
            };
            commit('ADD_PRODUCT', product);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async login({ commit, dispatch }, userData) {
      const auth = getAuth(this.$firebase);
      // Invalidate session when browser window is closed

      const res = await signInWithEmailAndPassword(
        auth,
        userData.email,
        userData.password,
      );

      if (!res.user.emailVerified) {
        const error = new Error('Not verified');
        error.code = 'Not verified';
        return Promise.reject(error);
      } else {
        commit('SET_VALIDATED', true);
      }

      const userId = res.user.uid;
      const email = res.user.email;
      commit('AUTH_USER', {
        userId,
        email,
      });

      await dispatch('fetchUser', userId, false);

      // Clear any products before retrieving new ones
      await commit('DISCARD_PRODUCTS');
      await dispatch('getProducts');
      router
        .replace({
          name: 'dashboard',
          params: {
            success: 'yes',
          },
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async loginSaml({ commit, dispatch }) {
      try {
        // Invalidate session when browser window is closed
        const provider = new SAMLAuthProvider(configData.auth.saml.provider);
        const res = await signInWithPopup(provider);

        const userId = res.user.uid;
        const email = res.user.email;
        commit('AUTH_USER', {
          userId,
          email,
        });

        if (!res.user.emailVerified) {
          const error = new Error('Not verified');
          error.code = 'Not verified';
          return Promise.reject(error);
        } else {
          commit('SET_VALIDATED', true);
        }
        try {
          await dispatch('fetchUser', userId, false);
        } catch (err) {
          console.log(err);
        }

        // Clear any products before retrieving new ones
        await commit('DISCARD_PRODUCTS');

        await dispatch('getProducts');
        router.replace({
          name: 'dashboard',
          params: {
            success: 'yes',
          },
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async loginOidc({ commit, dispatch }) {
      try {
        // Invalidate session when browser window is closed
        const provider = new OAuthProvider(configData.auth.odic.provider);
        const res = await signInWithPopup(provider);
        const userId = res.user.uid;
        const email = res.user.email;
        commit('AUTH_USER', {
          userId,
          email,
        });

        if (!res.user.emailVerified) {
          const error = new Error('Not verified');
          error.code = 'Not verified';
          return Promise.reject(error);
        } else {
          commit('SET_VALIDATED', true);
        }
        try {
          await dispatch('fetchUser', userId, false);
        } catch (err) {
          console.log(err);
        }

        // Clear any products before retrieving new ones
        await commit('DISCARD_PRODUCTS');

        await dispatch('getProducts');
        router.replace({
          name: 'dashboard',
          params: {
            success: 'yes',
          },
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async logout({ commit }) {
      try {
        const auth = getAuth(this.$firebase);
        auth.signOut();
        commit('CLEAR_AUTH_DATA');
        commit('DISCARD_USERS');
        commit('DISCARD_PRODUCTS');
        // Sign-out successful.
      } catch (err) {
        // nothing to do
      }
    },
    async fetchUser({ commit }, userId) {
      const db = getDatabase(this.$firebase);
      const dbRef = ref(db);
      const snapshot = await get(child(dbRef, `${firebaseUserUrl}/${userId}`));
      if (snapshot.exists()) {
        const userResult = snapshot.val();
        commit('STORE_USER_DATA', copy(userResult));
      }
    },
    async fetchUsers({ dispatch, commit }) {
      const db = getDatabase(this.$firebase);
      const dbRef = ref(db);
      const snapshot = await get(child(dbRef, `${firebaseUserUrl}`));
      await dispatch('discardUsers');
      if (snapshot.exists()) {
        const userListVal = snapshot.val();
        const userList = Object.keys(userListVal);
        userList.forEach((key) => {
          const childCopy = copy(userListVal[key]);
          commit('STORE_USER', {
            key,
            ...childCopy,
          });
        });
      }
    },
    async discardUsers({ commit }) {
      commit('DISCARD_USERS');
      return true;
    },
  },
  getters: {
    user: (state) => state.user,
    users: (state) => state.users,
    isAuthenticated(state) {
      const storedToken = ls.get('idToken');
      return storedToken != null && state.validated && state.user;
    },
    userId: (state) => state.userId,
    products: (state) => state.products,
    selectedTab: (state) => state.selectedTab,
  },
};
