export default {
  namespaced: true,
  state: {
    coreLoaded: false,
    serviceLoaded: false,
    uiLoaded: false,
    eventsLoaded: false,
    uiCssLoaded: false,
    clusteringLoaded: false,
    mapDataLoaded: false,
  },

  mutations: {
    SET_CORE_LOADED: (state, payload) => {
      state.coreLoaded = payload;
    },
    SET_SERVICE_LOADED: (state, payload) => {
      state.serviceLoaded = payload;
    },
    SET_UI_LOADED: (state, payload) => {
      state.uiLoaded = payload;
    },
    SET_EVENTS_LOADED: (state, payload) => {
      state.eventsLoaded = payload;
    },
    SET_UI_CSS_LOADED: (state, payload) => {
      state.uiCssLoaded = payload;
    },
    SET_CLUSTERING_LOADED: (state, payload) => {
      state.clusteringLoaded = payload;
    },
    SET_MAP_DATA_LOADED: (state, payload) => {
      state.mapDataLoaded = payload;
    },
  },
  getters: {
    coreLoaded: (state) => state.coreLoaded,
    serviceLoaded: (state) => state.serviceLoaded,
    uiLoaded: (state) => state.uiLoaded,
    eventsLoaded: (state) => state.eventsLoaded,
    uiCssLoaded: (state) => state.uiCssLoaded,
    clusteringLoaded: (state) => state.clusteringLoaded,
    mapDataLoaded: (state) => state.mapDataLoaded,
    mainLoaded: (state) =>
      state.coreLoaded &&
      state.serviceLoaded &&
      state.uiLoaded &&
      state.eventsLoaded &&
      state.uiCssLoaded,
    allLoaded: (state) =>
      state.coreLoaded &&
      state.serviceLoaded &&
      state.uiLoaded &&
      state.eventsLoaded &&
      state.uiCssLoaded &&
      state.clusteringLoaded &&
      state.mapDataLoaded,
  },
};
