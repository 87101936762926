import * as types from './types/mutation-types';
import { app } from '@/main';

export default {
  state: {
    lang: 'en',
  },
  mutations: {
    [types.SET_LANG](state, payload) {
      app.$i18n.locale = payload;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit(types.SET_LANG, payload);
    },
    async setLangNew({ commit }, payload) {
      if (payload in app.$i18n.messages) {
        commit(types.SET_LANG, payload);
      } else {
        try {
          const res = await import(`../../src/lang/${payload}.json`);
          app.$i18n.setLocaleMessage(payload, res.data);
          commit(types.SET_LANG, payload);
        } catch (e) {
          console.error(`Error${JSON.stringify(e)}`);
        }
      }
    },
  },
};
