import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi);

import Buefy from 'buefy';
import 'nprogress/nprogress.css';
import moment from 'moment';
import 'moment-timezone';
import VueClipboard from 'vue-clipboard2';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bulma-badge/dist/css/bulma-badge.min.css';
import VueFuse from 'vue-fuse';
import copy from 'fast-copy';

import { getAuth, onIdTokenChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebaseConfig.js';

import globalUtils from './mixins/utils';
import i18nMixin from './mixins/i18n';
import i18n from './lang/lang.js';
import configData from '@/config/config.json';
import store from './store/store-modules';
import App from '@/App.vue';
import './fontawesome';
import router from './router/index';
import { initialiseValidation } from './veeValidate';
import globalData from './mixins/globalData';
import { setIdToken } from '@/services/auth/index';

const firebaseApp = initializeApp(firebaseConfig);
Vue.prototype.$firebase = firebaseApp;
Vue.$firebase = firebaseApp;

Vue.component('VueFontawesome', FontAwesomeIcon);

Vue.prototype.$moment = moment;
Vue.$moment = moment;
Vue.prototype.$copy = copy;
Vue.$copy = moment;
Vue.$configData = Object.freeze(configData);
Vue.prototype.$configData = Object.freeze(configData);

Vue.use(VueClipboard);
Vue.mixin(globalData);
Vue.mixin(i18nMixin);
Vue.mixin(globalUtils);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fad',
});

Vue.use(VueFuse);

export const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App),
});

initialiseValidation(app.$t.bind(app));

const auth = getAuth(Vue.$firebase);
onIdTokenChanged(auth, async (user) => {
  if (user) {
    try {
      const idToken = await user.getIdToken(true);
      if (user.emailVerified) {
        await setIdToken(idToken);
      }
    } catch (err) {
      router.replace('signout').catch(() => {
        /** noop */
      });
    }
  } else {
    router.replace('/').catch(() => {
      /** noop */
    });
  }
});
