<!--
  Component for the registration of new users in the portal
-->
<template>
  <ValidationObserver v-slot="{ valid }" ref="valobs">
    <auth-container>
      <template #authForm>
        <form @submit.prevent="onSubmit">
          <label class="label has-text-left">Email</label>
          <b-field>
            <ValidationProvider
              v-slot="{ errors }"
              name="Email"
              rules="required|emailValidator"
            >
              <b-input
                id="email"
                v-model="email"
                type="email"
                icon="at"
                autocomplete="email"
              ></b-input>
              <span
                style="min-height: 1rem"
                class="help is-danger has-text-weight-bold"
                >{{ errors[0] }}</span
              >
            </ValidationProvider>
          </b-field>
          <label class="label has-text-left">Password</label>
          <validation-provider
            v-slot="{ errors }"
            rules="passwordValidator|required|password:@confirm"
            name="Password"
          >
            <b-field>
              <b-input
                id="password"
                v-model="password"
                type="password"
                icon="key"
                autocomplete="new-password"
              ></b-input>
            </b-field>
            <span
              style="min-height: 1rem"
              class="help is-danger has-text-weight-bold"
              >{{ errors[0] }}</span
            >
          </validation-provider>
          <label class="label has-text-left">Confirm password</label>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="confirm"
          >
            <b-field>
              <b-input
                id="confirm-password"
                v-model="confirmPassword"
                type="password"
                icon="key"
                autocomplete="new-password"
              ></b-input>
            </b-field>
            <span
              style="min-height: 1rem"
              class="help is-danger has-text-weight-bold"
              >{{ errors[0] }}</span
            >
          </validation-provider>
          <div class="control">
            <b-button
              :disabled="!valid"
              type="is-info"
              @click.prevent="onSubmit()"
            >
              {{ $t('Sign Up') }}
            </b-button>
          </div>
          <span
            :class="{
              help: true,
              'is-info': !error,
              'is-danger': error,
              'has-text-weight-bold': true,
            }"
          >
            {{ displayMessage }}
            <div v-if="accountExists">
              <router-link
                :to="{ name: 'passwordReset' }"
                class="has-text-primary"
                >{{ $t('Reset Password') }}</router-link
              >
            </div>
          </span>
        </form>
      </template>
    </auth-container>
  </ValidationObserver>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import AuthContainer from './AuthContainer';
import { signup } from '@/services/users/index';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'SignUp',
  components: {
    AuthContainer,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      displayMessage: null,
      error: false,
      accountExists: false,
    };
  },
  created() {
    extend('emailValidator', {
      message: () => {
        return this.$t('Email address not from appropriate domain');
      },
      validate: (value) => {
        const re = new RegExp(this.emailDomainRegex);
        return re.test(value);
      },
    });
    extend('passwordValidator', {
      message: () => {
        return `${this.$configData.signup.passwordValidation.message}`;
      },
      validate: (value) => {
        const re = new RegExp(this.$configData.signup.passwordValidation.regex);
        return re.test(value);
      },
    });
    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: this.$t(`Passwords don't match`),
    });
  },
  methods: {
    async onSubmit() {
      this.error = false;
      this.accountExists = false;
      const formData = {
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
      };
      this.submitted = true;
      try {
        await signup(formData);
        this.displayMessage = this.$t('Confirm signup');
      } catch (err) {
        this.error = true;
        if (err.code === 'auth/email-already-in-use') {
          this.displayMessage = this.$t(`Email already in use`);
          this.accountExists = true;
        } else if (err.code === 'auth/invalid-email') {
          this.displayMessage = this.$t(`Invalid email`);
        } else if (err.code === 'auth/weak-password') {
          this.displayMessage = this.$t(`Weak password`);
        } else {
          this.displayMessage = `${this.$t(
            'Oops, an error occurred, please contact your administrator reporting this error',
          )}: ${err}`;
        }
      }
    },
  },
});
</script>

<style scoped>
.hero {
  opacity: 0.7;
}
</style>
