import {
  getClickDataForCampaign,
  getCampaigns,
  getClickDetailForCampaign,
  getCampaignImpressions,
} from '@/services/marketing/index';

export default {
  namespaced: true,
  state: {
    campaigns: [],
    clicks: [],
    campaignClicks: {},
    campaignClickDetail: {},
    campaignImpressions: {},
  },
  mutations: {
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload;
    },
    SET_CAMPAIGN_CLICKS(state, payload) {
      state.campaignClicks[payload.campaignName] = payload.data;
    },
    SET_CAMPAIGN_CLICK_DETAIL(state, payload) {
      if (payload?.data && payload?.campaignName) {
        state.campaignClickDetail[payload.campaignName] = payload.data;
      }
    },
    SET_CAMPAIGN_IMPRESSION_DETAIL(state, payload) {
      if (payload?.campaignName) {
        state.campaignImpressions[payload.campaignName] = payload.data;
      }
    },
  },
  actions: {
    async getMarketingCampaigns({ commit }, isArchived) {
      const campaigns = await getCampaigns(isArchived);
      commit('SET_CAMPAIGNS', campaigns);
    },
    getClickDataForCampaigns({ state, commit }) {
      state.campaigns.forEach(async (campaign) => {
        const result = await getClickDataForCampaign(campaign);
        commit('SET_CAMPAIGN_CLICKS', {
          campaignName: campaign.campaignName,
          datasetId: campaign.datasetId,
          data: result,
        });
      });
    },
    getImpressionDataForCampaigns({ state, commit }) {
      state.campaigns.forEach(async (campaign) => {
        const result = await getCampaignImpressions(
          campaign.campaignName,
          campaign.datasetId,
        );
        commit('SET_CAMPAIGN_IMPRESSION_DETAIL', {
          campaignName: campaign.campaignName,
          datasetId: campaign.datasetId,
          data: result,
        });
      });
    },
    async getClickDataDetail({ commit }, campaign) {
      const data = await getClickDetailForCampaign(campaign);
      commit('SET_CAMPAIGN_CLICK_DETAIL', {
        campaignName: campaign.name,
        datasetId: campaign.datasetId,
        data,
      });
    },
  },
  getters: {
    campaigns: (state) => state.campaigns,
    campaignClicks: (state) => state.campaignClicks,
    campaignImpressions: (state) => state.campaignImpressions,
    campaignClickDetail: (state) => state.campaignClickDetail,
  },
};
