var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar has-background-header",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand"},[_c('a',{staticClass:"navbar-item has-background-header",attrs:{"href":"https://www.leadent.digital","target":"_new"}},[_c('img',{staticStyle:{"max-height":"50px"},attrs:{"src":_vm.logo}})]),_vm._m(0)]),_c('div',{staticClass:"navbar-menu has-background-customer",attrs:{"id":"portal-navbar"}},[_c('div',{staticClass:"navbar-start"},[_c('div',{staticClass:"buttons"},[(
            _vm.isAuthenticated &&
            _vm.currentRoute != 'signin' &&
            _vm.currentRoute != 'dashboard'
          )?_c('router-link',{staticClass:"navbar-item button is-primary nav-item",attrs:{"to":"/dashboard"}},[_vm._v(_vm._s(_vm.$t('Dashboard')))]):_vm._e(),(_vm.isAuthenticated && _vm.$route.path === '/omwResult')?_c('router-link',{staticClass:"navbar-item has-background-link button nav-item",attrs:{"to":"/omwLookup"}},[_vm._v(_vm._s(_vm.$t('Search Again')))]):_vm._e()],1)]),_c('div',{staticClass:"navbar-end"},[_c('div',{staticClass:"navbar-item"},[(
            _vm.isAuthenticated &&
            _vm.currentRoute != 'signin' &&
            _vm.currentRoute != 'welcome'
          )?_c('div',{staticClass:"navbar-item nav-item"},[_c('p',{staticClass:"has-text-weight-semibold has-text-header-text"},[_vm._v(" "+_vm._s(_vm.$configData.customer.displayName)+" ")])]):_vm._e(),(
            _vm.isAuthenticated &&
            _vm.currentRoute != 'signin' &&
            _vm.currentRoute != 'welcome'
          )?_c('p',{staticClass:"navbar-item has-text-weight-semibold has-text-header-text"},[_vm._v(" "+_vm._s(_vm.email)+" ")]):_vm._e(),_c('div',{staticClass:"buttons"},[(
              _vm.isAuthenticated &&
              _vm.currentRoute != 'signin' &&
              _vm.currentRoute != 'welcome'
            )?_c('div',{staticClass:"navbar-item nav-item"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.onLogout}},[_vm._v(" "+_vm._s(_vm.$t('Logout'))+" ")])],1):_vm._e(),(!_vm.isAuthenticated && _vm.currentRoute != 'welcome')?_c('div',{staticClass:"navbar-item nav-item"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.onHome}},[_vm._v(" "+_vm._s(_vm.$t('Home'))+" ")])],1):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-burger burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"false","data-target":"portal-navbar"}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }