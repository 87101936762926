import defaultActivityFields from '@/config/defaultActivityFields.json';
import configData from '@/config/config';

function initialState() {
  return {
    activities: [],
    loadResults: [],
    internalActivityTypes: [],
    customerActivityTypes: [],
    nonCustomerActivityTypes: [],
    sidebarOpen: false,
    activityFields: defaultActivityFields,
    shouldRetrieveResourcePrefs: 'No',
    shouldRetrieveActivityLinks: 'No',
    includeChildren: 'all',
    removeCancelled: 'Yes',
    removeNonCustomer: 'Yes',
    includeNonScheduled: 'No',
    removeDurations: 'Yes',
    attemptUpdate: 'No',
    extractEnvironment: undefined,
    loadEnvironment: undefined,
    daysToShift: 0,
    backToBucket: 'Yes',
    activityLimit: 100000,
    defaultFields: defaultActivityFields,
    dates: [new Date(), new Date()],
    resourceParent: configData.activityMigrator.topLevelResource,
    invalid: true,
  };
}

export default {
  namespaced: true,
  initialState,
  state: {
    activities: initialState().activities,
    loadResults: initialState().loadResults,
    internalActivityTypes: initialState().internalActivityTypes,
    customerActivityTypes: initialState().customerActivityTypes,
    nonCustomerActivityTypes: initialState().nonCustomerActivityTypes,
    sidebarOpen: initialState().sidebarOpen,
    activityFields: initialState().activityFields,
    includeChildren: initialState().includeChildren,
    removeCancelled: initialState().removeCancelled,
    removeNonCustomer: initialState().removeNonCustomer,
    attemptUpdate: initialState().attemptUpdate,
    extractEnvironment: initialState().extractEnvironment,
    loadEnvironment: initialState().loadEnvironment,
    daysToShift: initialState().daysToShift,
    backToBucket: initialState().backToBucket,
    activityLimit: initialState().activityLimit,
    defaultFields: defaultActivityFields,
    dates: initialState().dates,
    resourceParent: initialState().resourceParent,
    includeNonScheduled: initialState().includeNonScheduled,
    invalid: initialState().invalid,
    removeDurations: initialState().removeDurations,
    shouldRetrieveResourcePrefs: initialState().shouldRetrieveResourcePrefs,
    shouldRetrieveActivityLinks: initialState().shouldRetrieveActivityLinks,
  },

  mutations: {
    SET_INVALID(state, value) {
      state.invalid = value;
    },
    SET_NON_CUSTOMER_ACTIVITY_TYPES(state, value) {
      state.nonCustomerActivityTypes = value;
    },
    SET_RETRIEVE_ACTIVITY_LINKS(state, value) {
      state.shouldRetrieveActivityLinks = value;
    },
    SET_RETRIEVE_RESOURCE_PREFS(state, value) {
      state.shouldRetrieveResourcePrefs = value;
    },
    SET_REMOVE_DURATIONS(state, value) {
      state.removeDurations = value;
    },
    SET_INCLUDE_NONSCHEDULED(state, value) {
      state.includeNonScheduled = value;
    },
    SET_RESOURCE_PARENT(state, value) {
      state.resourceParent = value;
    },
    SET_DATES(state, value) {
      state.dates = value;
    },
    SET_INCLUDE_CHILDREN(state, value) {
      state.includeChildren = value;
    },
    SET_REMOVE_CANCELLED(state, value) {
      state.removeCancelled = value;
    },
    SET_REMOVE_NON_CUSTOMER(state, value) {
      state.removeNonCustomer = value;
    },
    SET_ATTEMPT_UPDATE(state, value) {
      state.attemptUpdate = value;
    },
    SET_EXTRACT_ENVIRONMENT(state, value) {
      state.extractEnvironment = value;
    },
    SET_LOAD_ENVIRONMENT(state, value) {
      state.loadEnvironment = value;
    },
    SET_DAYS_TO_SHIFT(state, value) {
      state.daysToShift = value;
    },
    SET_BACK_TO_BUCKET(state, value) {
      state.backToBucket = value;
    },
    SET_ACTIVITY_LIMIT(state, value) {
      state.activityLimit = value;
    },
    SET_ACTIVITY_FIELDS(state, value) {
      state.activityFields = value;
    },
    SET_SIDEBAR_OPEN(state, isOpen) {
      state.sidebarOpen = isOpen;
    },
    SET_LOAD_RESULTS(state, loadResults) {
      if (loadResults && loadResults.length > 0) {
        loadResults.forEach((row) => {
          state.loadResults.push(row);
        });
      }
    },
    DISCARD_LOAD_RESULTS(state) {
      while (state.loadResults.length > 0) {
        state.loadResults.pop();
      }
    },
    ADD_ACTIVITY(state, activity) {
      state.activities.push(activity);
    },
    DISCARD_ACTIVITIES(state) {
      while (state.activities.length > 0) {
        state.activities.pop();
      }
    },
    STORE_ACTIVITIES(state, activities) {
      state.activities = activities;
    },
    SET_ACTIVITY_TYPES(state, activityTypes) {
      while (state.internalActivityTypes.length > 0) {
        state.internalActivityTypes.pop();
      }
      while (state.customerActivityTypes.length > 0) {
        state.customerActivityTypes.pop();
      }
      //eslint-disable-next-line no-unused-vars
      for (let type of activityTypes.internalTypes) {
        state.internalActivityTypes.push(type);
      }
      //eslint-disable-next-line no-unused-vars
      for (let type of activityTypes.customerTypes) {
        state.customerActivityTypes.push(type);
      }
    },
    SET_INTERNAL_ACTIVITY_TYPES(state, newTypes) {
      while (state.internalActivityTypes.length > 0) {
        state.internalActivityTypes.pop();
      }
      newTypes.forEach((type) => {
        state.internalActivityTypes.push(type);
      });
    },
    SET_CUSTOMER_ACTIVITY_TYPES(state, activityTypes) {
      while (state.customerActivityTypes.length > 0) {
        state.customerActivityTypes.pop();
      }
      //eslint-disable-next-line no-unused-vars
      for (let type of activityTypes) {
        state.customerActivityTypes.push(type);
      }
    },
  },
  actions: {
    setInvalid({ commit }, value) {
      commit('SET_INVALID', value);
    },
    setShouldRetrieveResourcePrefs({ commit }, value) {
      commit('SET_RETRIEVE_RESOURCE_PREFS', value);
    },
    setShouldRetrieveActivityLinks({ commit }, value) {
      commit('SET_RETRIEVE_ACTIVITY_LINKS', value);
    },
    setRemoveDurations({ commit }, value) {
      commit('SET_REMOVE_DURATIONS', value);
    },
    setNonCustomerActivityTypes({ commit }, value) {
      commit('SET_NON_CUSTOMER_ACTIVITY_TYPES', value);
    },
    setIncludeNonScheduled({ commit }, value) {
      commit('SET_INCLUDE_NONSCHEDULED', value);
    },
    setResourceParent({ commit }, value) {
      commit('SET_RESOURCE_PARENT', value);
    },
    setDates({ commit }, value) {
      commit('SET_DATES', value);
    },
    setIncludeChildren({ commit }, value) {
      commit('SET_INCLUDE_CHILDREN', value);
    },
    setRemoveCancelled({ commit }, value) {
      commit('SET_REMOVE_CANCELLED', value);
    },
    setRemoveNonCustomer({ commit }, value) {
      commit('SET_REMOVE_NON_CUSTOMER', value);
    },
    setAttemptUpdate({ commit }, value) {
      commit('SET_ATTEMPT_UPDATE', value);
    },
    setExtractEnvironment({ commit }, value) {
      commit('SET_EXTRACT_ENVIRONMENT', value);
    },
    setLoadEnvironment({ commit }, value) {
      commit('SET_LOAD_ENVIRONMENT', value);
    },
    setDaysToShift({ commit }, value) {
      commit('SET_DAYS_TO_SHIFT', value);
    },
    setBackToBucket({ commit }, value) {
      commit('SET_BACK_TO_BUCKET', value);
    },
    setActivityLimit({ commit }, value) {
      commit('SET_ACTIVITY_LIMIT', value);
    },
    setActivityFields({ commit }, value) {
      commit('SET_ACTIVITY_FIELDS', value);
    },
    setSidebarOpen({ commit }, isOpen) {
      commit('SET_SIDEBAR_OPEN', isOpen);
    },
    removeNonCustomerActivityType({ state, commit }, type) {
      let newArray = state.internalActivityTypes.filter((row) => row != type);
      commit('SET_INTERNAL_ACTIVITY_TYPES', newArray);
    },
    setActivityTypes({ commit }, activityTypes) {
      commit('SET_ACTIVITY_TYPES', activityTypes);
      return Promise.resolve(true);
    },
    setCustomerActivityTypes({ commit }, activityTypes) {
      commit('SET_CUSTOMER_ACTIVITY_TYPES', activityTypes);
    },
    setInternalActivityTypes({ commit }, activityTypes) {
      commit('SET_INTERNAL_ACTIVITY_TYPES', {
        activityTypes,
      });
      return Promise.resolve(true);
    },
    storeLoadResults({ commit }, loadResults) {
      return new Promise((resolve) => {
        commit('DISCARD_LOAD_RESULTS');
        commit('SET_LOAD_RESULTS', loadResults);
        return resolve();
      });
    },
    discardLoadResults({ commit }) {
      commit('DISCARD_LOAD_RESULTS');
    },
    addActivity({ commit }, activity) {
      commit('ADD_ACTIVITY', activity);
    },
    discardActivities({ commit }) {
      commit('DISCARD_ACTIVITIES');
    },
    storeActivities({ commit }, activities) {
      commit('STORE_ACTIVITIES', activities);
    },
  },
  getters: {
    loadResults: (state) => state.loadResults,
    activities: (state) => state.activities,
    activityCount: (state) => state.activities.length,
    internalActivityTypes: (state) => state.internalActivityTypes,
    customerActivityTypes: (state) => state.customerActivityTypes,
    nonCustomerActivityTypes: (state) => state.nonCustomerActivityTypes,
    sidebarOpen: (state) => state.sidebarOpen,
    activityFields: (state) => state.activityFields,
    includeChildren: (state) => state.includeChildren,
    removeCancelled: (state) => state.removeCancelled,
    removeNonCustomer: (state) => state.removeNonCustomer,
    attemptUpdate: (state) => state.attemptUpdate,
    extractEnvironment: (state) => state.extractEnvironment,
    loadEnvironment: (state) => state.loadEnvironment,
    daysToShift: (state) => state.daysToShift,
    backToBucket: (state) => state.backToBucket,
    activityLimit: (state) => state.activityLimit,
    dateFrom: (state) => state.dates[0],
    dateTo: (state) => state.dates[1],
    dates: (state) => state.dates,
    resourceParent: (state) => state.resourceParent,
    invalid: (state) => state.invalid,
    includeNonScheduled: (state) => state.includeNonScheduled,
    removeDurations: (state) => state.removeDurations,
    shouldRetrieveResourcePrefs: (state) => state.shouldRetrieveResourcePrefs,
    shouldRetrieveActivityLinks: (state) => state.shouldRetrieveActivityLinks,
  },
};
