var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"valobs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('auth-container',{scopedSlots:_vm._u([{key:"authForm",fn:function(){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('label',{staticClass:"label has-text-left"},[_vm._v("Email")]),_c('b-field',[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|emailValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"email","type":"email","icon":"at","autocomplete":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"help is-danger has-text-weight-bold",staticStyle:{"min-height":"1rem"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('label',{staticClass:"label has-text-left"},[_vm._v("Password")]),_c('validation-provider',{attrs:{"rules":"passwordValidator|required|password:@confirm","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('b-input',{attrs:{"id":"password","type":"password","icon":"key","autocomplete":"new-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('span',{staticClass:"help is-danger has-text-weight-bold",staticStyle:{"min-height":"1rem"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('label',{staticClass:"label has-text-left"},[_vm._v("Confirm password")]),_c('validation-provider',{attrs:{"rules":"required","name":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('b-input',{attrs:{"id":"confirm-password","type":"password","icon":"key","autocomplete":"new-password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('span',{staticClass:"help is-danger has-text-weight-bold",staticStyle:{"min-height":"1rem"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"disabled":!valid,"type":"is-info"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t('Sign Up'))+" ")])],1),_c('span',{class:{
            help: true,
            'is-info': !_vm.error,
            'is-danger': _vm.error,
            'has-text-weight-bold': true,
          }},[_vm._v(" "+_vm._s(_vm.displayMessage)+" "),(_vm.accountExists)?_c('div',[_c('router-link',{staticClass:"has-text-primary",attrs:{"to":{ name: 'passwordReset' }}},[_vm._v(_vm._s(_vm.$t('Reset Password')))])],1):_vm._e()])],1)]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }