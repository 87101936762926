export default {
  namespaced: true,
  state: {
    environmentList: [],
    targetEnvironments: [],
  },
  mutations: {
    SET_ENVIRONMENTS_LIST(state, environments) {
      state.environmentList = environments;
    },
  },
  actions: {
    setEnvironments({ commit }, environments) {
      commit('SET_ENVIRONMENTS_LIST', environments);
    },
  },
  getters: {
    environmentList: (state) => state.environmentList,
    targetEnvironments: (state) =>
      state.environmentList
        ? state.environmentList.filter((env) => {
            if (!env.production) {
              return env;
            }
          })
        : [],
  },
};
