import axios from 'axios';
import configData from '../../config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.environmentLookup.url,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }
    throw new Error('No access token Found');
  },
  (err) => Promise.reject(err),
);

export default async function getEnvironments(retrievePasswords, userId) {
  if (!userId) {
    console.log('user not found');
    return [];
  }

  const params = new URLSearchParams();
  params.append('userId', userId);
  params.append('customer', configData.customer.name);
  params.append('passwords', retrievePasswords);
  const options = {
    method: 'GET',
    params,
  };
  return apiClient(options);
}

export async function createEnvironment({
  customer,
  friendlyName,
  name,
  username,
  password,
  production,
  userId,
}) {
  const environmentPayload = {
    customer,
    friendlyName,
    name,
    username,
    password,
    production,
    userId,
  };
  const options = {
    method: 'POST',
    data: environmentPayload,
  };
  return apiClient(options);
}

export async function updateEnvironment({
  customer,
  name,
  friendlyName,
  production,
  password,
  username,
  userId,
}) {
  const environmentPayload = {
    customer,
    name,
    friendlyName,
    production,
    password,
    username,
    userId,
  };
  const options = {
    method: 'PATCH',
    data: environmentPayload,
    url: `/${name}`,
  };
  return apiClient(options);
}

export async function deleteEnvironment({ customer, userId, name }) {
  const params = new URLSearchParams();
  params.append('customer', customer);
  params.append('userId', userId);

  const options = {
    url: `/${name}`,
    method: 'DELETE',
    params,
  };
  return apiClient(options);
}
