import configData from '@/config/config.json';
import { getIdToken } from '@/services/auth/index';

const baseURL = configData.marketing.baseUrl;

import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${baseURL}/marketing`,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

export async function getCampaigns(includeArchived, datasetId) {
  const params = new URLSearchParams();
  params.append('includeArchived', String(includeArchived));
  if (datasetId) {
    params.append('datasetId', String(datasetId));
  }
  const { data } = await apiClient({
    method: 'GET',
    params,
  });
  return data;
}

export async function createCampaign(
  {
    campaignName,
    isPublished = false,
    clickUrl,
    categories,
    isDefault,
    isArchived,
    datasetId,
  },
  file,
) {
  const campaignPayload = {
    campaignName,
    isPublished,
    clickUrl,
    categories,
    isDefault,
    isArchived,
    datasetId,
  };

  const formData = new FormData();
  formData.append('image', file);

  const url = `/image/${file.name}`;
  const { data } = await apiClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  campaignPayload.imageUrl = data.url;
  await apiClient.post('', campaignPayload);
}

export async function getClickDetailForCampaign(campaign) {
  const data = await apiClient.get(
    `/clicked/detail/${campaign.campaignName}?datasetId=${campaign.datasetId}`,
  );
  return data[campaign.campaignName];
}

export async function getCampaignImpressions(campaignName, datasetId) {
  const { data } = await apiClient.get(
    `/impressions/${campaignName}?datasetId=${datasetId}`,
  );
  return data;
}

export async function updateCampaign({
  campaignName,
  clickUrl,
  categories,
  isDefault,
  datasetId,
}) {
  const campaignPayload = {
    clickUrl,
    categories,
    isDefault,
    datasetId,
  };
  return apiClient.patch(`/${campaignName}`, campaignPayload);
}

export async function archiveCampaign(campaign) {
  return apiClient.patch(
    `/${campaign.campaignName}/archive/?isArchived=true&datasetId=${campaign.datasetId}`,
    {},
  );
}
export async function unarchiveCampaign(campaign) {
  return apiClient.patch(
    `/${campaign.campaignName}/archive?isArchived=false&datasetId=${campaign.datasetId}`,
    {},
  );
}

export async function unpublishCampaign(campaign) {
  return apiClient.patch(
    `/${campaign.campaignName}/unpublish?datasetId=${campaign.datasetId}`,
    {},
  );
}

export async function publishCampaign(campaign) {
  return apiClient.patch(
    `/${campaign.campaignName}/publish?datasetId=${campaign.datasetId}`,
    {},
  );
}

export async function makeCampaignDefault(campaign) {
  return apiClient.patch(
    `/${campaign.campaignName}/default?datasetId=${campaign.datasetId}`,
    {},
  );
}

export async function restrictCampaign(campaign) {
  return apiClient.patch(
    'omw',
    `/${campaign.campaignName}/restrict?datasetId=${campaign.datasetId}`,
    {},
  );
}

export async function getClickDataForCampaign(campaign) {
  const { data } = await apiClient.get(
    `/clicked/${campaign.campaignName}?datasetId=${campaign.datasetId}`,
  );
  return data;
}
