const firebaseConfig = {
  apiKey: 'AIzaSyDLWtrAlIkEIxjgDgW-XIkp7f1NTLy9Of0',
  authDomain: 'eonenergy-test.firebaseapp.com',
  databaseURL:
    'https://eonenergy-test-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'eonenergy-test',
  storageBucket: 'eonenergy-test.appspot.com',
  messagingSenderId: '95347290630',
};

export { firebaseConfig };
