export default {
  namespaced: true,
  state: {
    startLocations: [],
    polygons: {},
    activities: [],
    resourceDate: new Date().valueOf(),
    startDateTime: new Date().setHours(7).valueOf(),
    activityDateFrom: null,
    activityDateTo: null,
  },
  mutations: {
    SET_RESOURCE_DATE: (state, date) => {
      state.resourceDate = date;
    },
    SET_START_DATE_TIME: (state, date) => {
      state.startDateTime = date;
    },
    SET_ACTIVITY_DATE_FROM: (state, dateFrom) => {
      state.activityDateFrom = dateFrom;
    },
    SET_ACTIVITY_DATE_TO: (state, dateTo) => {
      state.activityDateTo = dateTo;
    },
    ADD_POLYGON: (state, polygon) => {
      state.polygons[polygon.id] = polygon;
    },
    DISCARD_POLYGONS: (state) => {
      state.polygons = {};
    },
    ADD_START_LOCATION: (state, startLocation) => {
      state.startLocations.push(startLocation);
    },
    DISCARD_START_LOCATIONS: (state) => {
      while (state.startLocations.length > 0) {
        state.startLocations.pop();
      }
    },
    STORE_START_LOCATIONS: (state, startLocations) => {
      //eslint-disable-next-line no-unused-vars
      for (let startLocation of startLocations) {
        state.startLocations.push(startLocation);
      }
    },
    STORE_ACTIVITIES: (state, activities) => {
      //eslint-disable-next-line no-unused-vars
      for (let activity of activities) {
        state.activities.push(activity);
      }
    },
    DISCARD_ACTIVITIES: (state) => {
      while (state.activities.length > 0) {
        state.activities.pop();
      }
    },
    DISCARD_DATES: (state) => {
      state.activityDateTo = null;
      state.activityDateFrom = null;
      state.resourceDate = null;
    },
  },
  actions: {
    setResourceDate: ({ commit }, date) => {
      commit('SET_RESOURCE_DATE', date);
    },
    setStartDateTime: ({ commit }, date) => {
      commit('SET_START_DATE_TIME', date);
    },
    setActivityDateFrom: ({ commit }, dateFrom) => {
      commit('SET_ACTIVITY_DATE_FROM', dateFrom);
    },
    setActivityDateTo: ({ commit }, dateTo) => {
      commit('SET_ACTIVITY_DATE_TO', dateTo);
    },
    addPolygon({ commit }, polygon) {
      commit('ADD_POLYGON', polygon);
    },
    addStartLocation({ commit }, startLocation) {
      commit('ADD_START_LOCATION', startLocation);
    },
    discardStartLocations({ commit }) {
      commit('DISCARD_START_LOCATIONS');
    },
    storeStartLocations({ commit }, startLocations) {
      commit('DISCARD_START_LOCATIONS');
      commit('STORE_START_LOCATIONS', startLocations);
    },
    discardPolygons({ commit }) {
      commit('DISCARD_POLYGONS');
    },
    storeActivities({ commit }, activities) {
      commit('STORE_ACTIVITIES', activities);
      return Promise.resolve();
    },
    discardActivities({ commit }) {
      commit('DISCARD_ACTIVITIES');
    },
    discardDates({ commit }) {
      commit('DISCARD_DATES');
    },
    discardAllData({ commit }) {
      commit('DISCARD_DATES');
      commit('DISCARD_ACTIVITIES');
      commit('DISCARD_POLYGONS');
      commit('DISCARD_START_LOCATIONS');
    },
  },
  getters: {
    startLocations: (state) => state.startLocations,
    startLocationCount: (state) => state.startLocations.length,
    polygons: (state) => state.polygons,
    polygonCount: (state) => state.polygons.length,
    activities: (state) => state.activities,
    activityCount: (state) => state.activities.length,
    resourceDate: (state) => state.resourceDate,
    startDateTime: (state) => state.startDateTime,
    activityDateFrom: (state) => state.activityDateFrom,
    activityDateTo: (state) => state.activityDateTo,
  },
};
