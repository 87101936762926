import Vue from 'vue';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { getDatabase, ref, set, remove, update } from '@firebase/database';
import configData from '@/config/config.json';

const firebaseUserUrl = configData.customer.firebaseUserUrl;

function deleteUserInFirebase(user) {
  const db = getDatabase(Vue.$firebase);
  const dbRef = ref(db, `${firebaseUserUrl}/${user.id}`);
  return remove(dbRef);
}

/* eslint-disable  */
async function updateUserInFirebase(user) {
  /* eslint-disable end */
  const updates = {};
  updates[`${firebaseUserUrl}/${user.id}`] = user;
  // We don't need the id in the main user object in Firebase
  delete user.id;

  // Set any unspecified values to false
  const userKeys = Object.keys(user);
  userKeys.forEach((key) => {
    if (!user[key]) {
      user[key] = false;
    }
  });

  try {
    const db = getDatabase(Vue.$firebase);
    const dbRef = ref(db);
    return update(dbRef, updates);
  } catch (error) {
    console.log(error);
  }
}

function storeUserDataInFirebase(userData) {
  //eslint-disable-line
  // If a Leadent address, give admin privileges
  let leadentDigitalRegex = /leadent\.digital/;
  let leadentSolutionsRegex = /leadentsolutions\.com/;
  let leadentMatch =
    !!userData.email.match(leadentDigitalRegex) || !!userData.email.match(leadentSolutionsRegex);
  const db = getDatabase(Vue.$firebase);
  const dbRef = ref(db, `${firebaseUserUrl}/${userData.userId}`);

  set(dbRef, {
    activityMigrator: false,
    dataLoaders: false,
    omwLookup: false,
    omwStats: false,
    feedback: false,
    email: userData.email,
    leadent: leadentMatch,
    superUser: false,
    isochrone: false,
    customerAdministrator: false,
    realTimeTravel: false,
    marketing: false,
  });
}

async function signup(userData) {
  const auth = getAuth(Vue.$firebase);

  const res = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
  const actionCodeSettings = {
    url: `${configData.auth.signin.url}?email=${userData.email}`,
  };
  await sendEmailVerification(res.user, actionCodeSettings);

  userData.userId = res.user.uid;
  await storeUserDataInFirebase(userData);
}

export { signup, deleteUserInFirebase, storeUserDataInFirebase, updateUserInFirebase };
