import configData from '../config/config.json';

export default {
  namespaced: true,
  state: {
    startLocations: [],
    polygons: {},
    activities: [],
    resourceDate: new Date().valueOf(),
    startDateTime: new Date().setHours(8).valueOf(),
    activityDateFrom: null,
    activityDateTo: null,
    shifts: [],
    resourceParent: configData.isochrone.topLevelResource,
    environment: '',
    maxTime: 20,
    includeNonScheduled: false,
    startOrHome: 'Start',
    radius: 20,
  },
  mutations: {
    SET_RADIUS(state, value) {
      state.radius = value;
    },
    SET_START_OR_HOME(state, value) {
      state.startOrHome = value;
    },
    SET_MAX_TIME(state, time) {
      state.maxTime = time;
    },
    SET_INCLUDE_NON_SCHEDULED(state, includeNonScheduled) {
      state.includeNonScheduled = includeNonScheduled;
    },
    SET_ENVIRONMENT(state, environment) {
      state.environment = environment;
    },
    SET_RESOURCE_PARENT(state, resourceParent) {
      state.resourceParent = resourceParent;
    },
    SET_SHIFTS: (state, shifts) => {
      shifts.forEach((shift) => {
        state.shifts.push(shift);
      });

      for (const startLocation of state.startLocations) {
        for (const shift of state.shifts) {
          if (shift.resourceId === startLocation.resourceId) {
            startLocation.isWorking = shift.isWorking;
            break;
          }
        }
      }
    },
    SET_RESOURCE_DATE: (state, date) => {
      state.resourceDate = date;
    },
    SET_RESOURCE_HOUR: (state, hour) => {
      state.resourceHour = hour;
    },
    SET_RESOURCE_MINUTES: (state, minutes) => {
      state.resourceMinutes = minutes;
    },
    SET_ACTIVITY_DATE_FROM: (state, dateFrom) => {
      state.activityDateFrom = dateFrom;
    },
    SET_ACTIVITY_DATE_TO: (state, dateTo) => {
      state.activityDateTo = dateTo;
    },
    SET_START_DATE_TIME: (state, date) => {
      state.startDateTime = date;
    },
    ADD_POLYGON: (state, polygon) => {
      state.polygons[polygon.resourceId] = polygon;
    },
    DISCARD_POLYGONS: (state) => {
      state.polygons = {};
    },
    DISCARD_SHIFTS: (state) => {
      while (state.shifts.length > 0) {
        state.shifts.pop();
      }
      state.startLocations.forEach((loc) => {
        delete loc.isWorking;
      });
    },
    ADD_START_LOCATION: (state, startLocation) => {
      state.startLocations.push(startLocation);
    },
    DISCARD_START_LOCATIONS: (state) => {
      while (state.startLocations.length > 0) {
        state.startLocations.pop();
      }
    },
    STORE_START_LOCATIONS: (state, startLocations) => {
      //eslint-disable-next-line no-unused-vars
      for (let startLocation of startLocations) {
        state.startLocations.push(startLocation);
      }
    },
    STORE_ACTIVITIES: (state, activities) => {
      //eslint-disable-next-line no-unused-vars
      for (let activity of activities) {
        state.activities.push(activity);
      }
      return Promise.resolve();
    },
    DISCARD_ACTIVITIES: (state) => {
      while (state.activities.length > 0) {
        state.activities.pop();
      }
    },
    DISCARD_DATES: (state) => {
      state.activityDateTo = null;
      state.activityDateFrom = null;
    },
  },
  actions: {
    addPolygon({ commit }, polygon) {
      commit('ADD_POLYGON', polygon);
    },
    discardPolygons({ commit }) {
      commit('DISCARD_POLYGONS');
    },
    setRadius({ commit }, value) {
      commit('SET_RADIUS', value);
    },
    setStartOrHome({ commit }, value) {
      commit('SET_START_OR_HOME', value);
    },
    setMaxTime({ commit }, time) {
      commit('SET_MAX_TIME', time);
    },
    setIncludeNonScheduled({ commit }, includeNonScheduled) {
      commit('SET_INCLUDE_NON_SCHEDULED', includeNonScheduled);
    },
    setEnvironment({ commit }, environment) {
      commit('SET_ENVIRONMENT', environment);
    },
    setResourceParent({ commit }, resourceParent) {
      commit('SET_RESOURCE_PARENT', resourceParent);
    },
    setResourceDate: ({ commit }, date) => {
      commit('SET_RESOURCE_DATE', date);
      return Promise.resolve(true);
    },
    setResourceHour: ({ commit }, hour) => {
      commit('SET_RESOURCE_HOUR', hour);
      return Promise.resolve(true);
    },
    setResourceMinutes: ({ commit }, minutes) => {
      commit('SET_RESOURCE_MINUTES', minutes);
      return Promise.resolve(true);
    },
    setActivityDateFrom: ({ commit }, dateFrom) => {
      commit('SET_ACTIVITY_DATE_FROM', dateFrom);
      return Promise.resolve(true);
    },
    setActivityDateTo: ({ commit }, dateTo) => {
      commit('SET_ACTIVITY_DATE_TO', dateTo);
      return Promise.resolve(true);
    },
    setStartDateTime: ({ commit }, date) => {
      commit('SET_START_DATE_TIME', date);
      return Promise.resolve(true);
    },
    addStartLocation({ commit }, startLocation) {
      commit('ADD_START_LOCATION', startLocation);
      return Promise.resolve(true);
    },
    discardStartLocations({ commit }) {
      commit('DISCARD_START_LOCATIONS');
      return Promise.resolve(true);
    },
    storeStartLocations({ commit }, startLocations) {
      commit('DISCARD_START_LOCATIONS');
      commit('STORE_START_LOCATIONS', startLocations);
      return Promise.resolve(true);
    },
    storeOnShift({ commit }, shifts) {
      commit('DISCARD_SHIFTS');
      commit('SET_SHIFTS', shifts);
      return Promise.resolve(true);
    },
    storeActivities({ commit }, activities) {
      commit('STORE_ACTIVITIES', activities);
      return Promise.resolve(true);
    },
    discardActivities({ commit }) {
      commit('DISCARD_ACTIVITIES');
      return Promise.resolve(true);
    },
    discardDates({ commit }) {
      commit('DISCARD_DATES');
      return Promise.resolve(true);
    },
    discardShifts({ commit }) {
      commit('DISCARD_SHIFTS');
      return Promise.resolve(true);
    },
    discardAllData({ commit }) {
      commit('DISCARD_DATES');
      commit('DISCARD_ACTIVITIES');
      commit('DISCARD_POLYGONS');
      commit('DISCARD_START_LOCATIONS');
      commit('DISCARD_SHIFTS');
      return Promise.resolve(true);
    },
  },
  getters: {
    startLocations: (state) => state.startLocations,
    startLocationCount: (state) => state.startLocations.length,
    polygons: (state) => state.polygons,
    activities: (state) => state.activities,
    activityCount: (state) => state.activities.length,
    resourceDate: (state) => state.resourceDate,
    activityDateFrom: (state) => state.activityDateFrom,
    activityDateTo: (state) => state.activityDateTo,
    startDateTime: (state) => state.startDateTime,
    shifts: (state) => state.shifts,
    resourceParent: (state) => state.resourceParent,
    environment: (state) => state.environment,
    maxTime: (state) => state.maxTime,
    includeNonScheduled: (state) => state.includeNonScheduled,
    startOrHome: (state) => state.startOrHome,
    radius: (state) => state.radius,
  },
};
