import { extend, localize } from 'vee-validate';
import configData from '@/config/config.json';
import moment from 'moment';

export function initialiseValidation(i18n) {
  extend('required', {
    validate(value) {
      return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1,
      };
    },
    message: 'The {_field_} field is required',
    computesRequired: true,
  });

  const dict = {
    custom: {
      email: {
        regex: `${i18n('Email addresses must end in')} ${
          configData.signup.emailDomain
        }`,
      },
      'confirm-password': {
        is: `${i18n('Passwords must match')}`,
      },
    },
  };

  localize('en', dict);

  extend('verify_password', {
    getMessage: () => i18n(configData.signup.passwordValidation.message),
    validate: (value) => {
      let passwordRegex = new RegExp(configData.signup.passwordValidation.regex); //eslint-disable-line
      return passwordRegex.test(value);
    },
  });

  extend(
    'max_date_range',
    {
      getMessage: () =>
        `Unfortunately, OFSC poses the restriction of a 30 day window for activity retrieval but please check back soon, we'll soon be implementing a workaround`,
      validate: (value, args) => {
        const endDateMoment = moment(value);
        const startDateMoment = moment(args[0]);
        const numberOfDays = args[1];
        endDateMoment.subtract(numberOfDays, 'days');
        return endDateMoment.isSameOrBefore(startDateMoment, 'days');
      },
    },
    {
      hasTarget: true,
    },
  );
}
