<script>
// Main use for this mixin is to provide global visibility of access authorisation
import { mapGetters } from 'vuex';
import { getAuth } from 'firebase/auth';
import packageJson from '../../package.json';
import getEnvironmentsService from '@/services/environments/index';
import { getIdToken, setIdToken } from '@/services/auth/index';

export default {
  data() {
    return {
      statsColors: {
        initialPageLoads: '#E61F00',
        pageLoads: '#34B73B',
        initialNotifications: '#f39c12',
        notifications: '#2d94f2',
        percentageEngagedActivities: '#3d4849',
        averagePageLoads: '#999999',
      },
      version: packageJson.version,
      shouldShowReleaseNotes: false,
    };
  },
  computed: {
    ...mapGetters('storeAuth', ['isAuthenticated', 'products', 'user']),
    ...mapGetters('storeEnvironments', [
      'environmentList',
      'targetEnvironments',
    ]),
    auth() {
      return this.isAuthenticated;
    },
    idToken: {
      get() {
        return getIdToken();
      },
      set(val) {
        setIdToken(val);
      },
    },
    userId() {
      return getAuth().currentUser.uid;
    },
    customer() {
      return this.$configData.customer.name;
    },
    resourceConfigEnabled() {
      return this.$configData?.omwResourceConfig?.enabled;
    },
    purchasedFeedback() {
      return this.checkProductPurchased('omwFeedback');
    },
    purchasedOmw() {
      return this.checkProductPurchased('omw');
    },
    purchasedOmwStats() {
      return this.checkProductPurchased('omwStats');
    },
    purchasedDataLoaders() {
      return this.checkProductPurchased('dataLoaders');
    },
    purchasedRealTimeTravel() {
      return this.checkProductPurchased('realTimeTravel');
    },
    purchasedMarketing() {
      return this.checkProductPurchased('marketing');
    },
    purchasedInventoryManager() {
      return this.checkProductPurchased('inventoryManager');
    },
    purchasedActivityMigrator() {
      return this.checkProductPurchased('activityMigrator');
    },
    purchasedIsochrone() {
      return this.checkProductPurchased('isochrone');
    },
    purchasedPdflify() {
      return this.checkProductPurchased('pdflify');
    },
    purchasedIsochroneCSV() {
      return this.checkProductPurchased('isochroneCSV');
    },
    emailDomainRegex() {
      return this.$configData.signup.emailDomainRegex;
    },
    emailDomain() {
      return this.$configData.signup.emailDomain;
    },
    dataLoaderAccess() {
      return !this.user ? false : this.user.dataLoaders || false;
    },
    isochroneAccess() {
      return !this.user ? false : this.user.isochrone || false;
    },
    pdflifyAccess() {
      return !this.user ? false : this.user.pdflify || false;
    },
    realTimeTravelAccess() {
      return !this.user ? false : this.user.rtt || false;
    },
    marketingAccess() {
      return !this.user ? false : this.user.marketing || false;
    },
    inventoryAccess() {
      return !this.user ? false : this.user.inventory || false;
    },
    activityMigratorAccess() {
      return !this.user ? false : this.user.activityMigrator || false;
    },
    omwLookupAccess() {
      return !this.user ? false : this.user.omwLookup || false;
    },
    omwStatsAccess() {
      return !this.user ? false : this.user.omwStats || false;
    },
    feedbackAccess() {
      return !this.user ? false : this.user.feedback || false;
    },
    customerAdminAccess() {
      return !this.user ? false : this.user.customerAdministrator || false;
    },
    adminAccess() {
      if (!this.user) {
        return false;
      } else {
        return this.user.leadent || this.user.superUser || false;
      }
    },
    leadentAccess() {
      if (!this.user) {
        return false;
      } else {
        return this.user.leadent || false;
      }
    },
    configData() {
      return this.$configData;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    pluralise(label, value) {
      const returnVal = value != 1 ? label : label.slice(0, -1);
      return returnVal;
    },
    handleCsvFields(fields) {
      let fieldObjectArray = fields.map((field) => {
        return {
          label: field,
          title: field,
        };
      });
      this.activityFields = fieldObjectArray;
    },
    showReleaseNotes() {
      this.shouldShowReleaseNotes = true;
    },
    checkProductPurchased(key) {
      let purchased = false;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].product === key) {
          if (this.products[i].enabled) {
            purchased = true;
            break;
          }
        }
      }
      // Assume not purchased if not found.
      return purchased;
    },
    emptyArray(arr) {
      if (arr) {
        while (arr.length > 0) {
          arr.pop();
        }
      }
      return arr;
    },
    isErrorUnauthorised(err) {
      return err?.response?.status && err.response.status === 401;
    },
    async fetchEnvironments(retrievePasswords) {
      const user = this.$store.getters['storeAuth/userId'];
      const { data: environmentList } = await getEnvironmentsService(
        retrievePasswords,
        user,
        this.idToken,
      );
      this.$store.dispatch(
        'storeEnvironments/setEnvironments',
        environmentList,
      );
    },
  },
};
</script>
