import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/store-modules';
import WelcomePage from '@/components/welcome/Welcome';

import SignupPage from '@/components/auth/Signup';
import SigninPage from '@/components/auth/Signin';
import PasswordReset from '@/components/auth/PasswordReset';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      name: 'welcome',
      path: '/',
      component: WelcomePage,
      meta: {
        title: 'Welcome',
      },
    },
    {
      name: 'passwordReset',
      path: '/passwordReset',
      component: PasswordReset,
      meta: {
        title: 'Password Reset',
      },
    },
    {
      name: 'signup',
      path: '/signup',
      component: SignupPage,
      meta: {
        title: 'Sign Up',
      },
    },
    {
      name: 'signin',
      path: '/signin',
      component: SigninPage,
      props: true,
      meta: {
        title: 'Login',
      },
    },
    {
      name: 'signout',
      path: '/signout',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/auth/Logout'),
      meta: {
        title: 'Logout',
      },
    },
    {
      name: 'dashboard',
      path: '/dashboard',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/dashboard/Dashboard'),
      meta: {
        requiresAuth: true,
        title: 'Dashboard',
      },
    },
    {
      name: 'feedbackViewer',
      path: '/feedbackViewer',
      component: () =>
        import(
          /* webpackPrefetch: true */ '@/components/omw/feedback/FeedbackViewer'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'feedback',
        title: 'Feedback Viewer',
      },
    },
    {
      name: 'omwLookup',
      path: '/omwLookup',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/omw/lookup/OmwLookup'),
      meta: {
        requiresAuth: true,
        specificAuth: 'omwLookup',
        title: 'On My Way Lookup',
      },
    },
    {
      name: 'omwStats',
      path: '/omwStats',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/omw/stats/OmwStats'),
      meta: {
        requiresAuth: true,
        specificAuth: 'omwStats',
        title: 'On My Way Statistics',
      },
    },
    {
      name: 'marketing',
      path: '/marketing',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/omw/marketing/ManageCampaigns.vue'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'marketing',
        title: 'On My Way Marketing Campaigns',
      },
    },
    {
      name: 'activityMigrator',
      path: '/activityMigrator',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/loaders/activityMigrator/ActivityMigratorMain'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'activityMigrator',
        title: 'OFSC Activity Migrator',
      },
    },
    {
      name: 'inventoryManager',
      path: '/inventoryManager',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/inventory/InventoryDisplay'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'inventoryManager',
        title: 'Inventory',
      },
    },
    {
      name: 'admin',
      path: '/admin',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/admin/UserAdmin'),
      meta: {
        requiresAuth: true,
        specificAuth: 'superUser,customerAdministrator',
        title: 'Administration',
      },
    },
    {
      name: 'envAdmin',
      path: '/envAdmin',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/admin/EnvironmentAdmin'
        ),
      meta: {
        requiresAuth: true,
        title: 'Environment Admin',
      },
    },
    {
      name: 'loaderContainer',
      path: '/loaders',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/loaders/LoaderContainer'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'dataLoaders',
        title: 'OFSC Data Management',
      },
    },
    {
      name: 'isochrone',
      path: '/isochrone',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/isochrone/IsoMapContainer'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'isochrone',
        title: 'Resource Coverage Analytics',
      },
    },
    {
      name: 'isochroneCsv',
      path: '/isochrone-csv',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/isochrone/IsochroneCsv'
        ),
      meta: {
        requiresAuth: false,
        // specificAuth: 'isochrone',
        title: 'Resource Coverage Analytics',
      },
    },
    {
      name: 'ofscStats',
      path: '/stats',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/viewers/OfscStatsViewer'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'superUser',
        title: 'OFSC Statistics',
      },
    },
    {
      name: 'rtt',
      path: '/rtt',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/real-time-travel/RealTimeTravel'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'superUser',
        title: 'Real Time Travel',
      },
    },
    {
      name: 'extractCalendars',
      path: '/extractCalendars',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/extractors/CalendarExtract'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'dataLoaders',
        title: 'Calendar Extract',
      },
    },
    {
      name: 'extractResources',
      path: '/extractResources',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/extractors/ResourceExtract'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'dataLoaders',
        title: 'Resource Extract',
      },
    },
    {
      name: 'supportSubmission',
      path: '/supportSubmission',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/admin/SupportForm'
        ),
      meta: {
        requiresAuth: true,
        title: 'Submit Support Ticket',
      },
    },
    {
      name: 'omwResourceConfig',
      path: '/omwResourceConfig',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/omw/resources/DisplayResourceConfig'
        ),
      meta: {
        requiresAuth: true,
        title: 'OMW Resource Config',
      },
    },
    {
      name: 'formsDisplay',
      path: '/formSearch',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/ofsc/forms/FormsDisplay'
        ),
      meta: {
        requiresAuth: true,
        title: 'OFSC Form Search',
      },
    },
    {
      name: 'rebookStats',
      path: '/rebookStats',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/omw/rebookStats/RebookStatsDisplay'
        ),
      meta: {
        requiresAuth: true,
        title: 'OFSC Form Search',
      },
    },
    {
      name: 'omwKillSwitch',
      path: '/omw-kill-switch',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/omw/killSwitch/KillSwitch.vue'
        ),
      meta: {
        requiresAuth: true,
        title: 'OMW Outage Configuration',
      },
    },
    {
      name: 'default',
      path: '*',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/dashboard/Dashboard'),
      meta: {
        requiresAuth: true,
        title: 'Dashboard',
      },
    },
  ],
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  const storedUser = store.getters['storeAuth/user'];
  const verifiedUser = store.getters['storeAuth/isAuthenticated'];

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && (!verifiedUser || !storedUser)) {
    store.dispatch('storeAuth/logout');
  }
  // We're logged in and the target route doesn't require authorisation
  else if (!requiresAuth && verifiedUser) {
    if (storedUser.leadent) {
      next();
    }
    // We're logged in and the target route doesn't require authorisation
    // and we've requested the welcome page so 'forward' to the dashboard
    else if (to.name === 'welcome') {
      next('dashboard');
    }
    // We're logged in and the target route doesn't require authorisation
    // send the user to where they asked for
    else {
      next();
    }
  } else {
    // We're logged in and the target route DOES require authorisation
    to.matched.some((record) => {
      // We're logged in and the target route DOES require authorisation
      // and if there's a specific level of auth required
      if (record.meta.specificAuth) {
        // We're logged in and the target route DOES require authorisation
        // and specific authorisation is needed
        // and the current user has that authorisation
        let specificAuthFound = false;
        const specificAuthArray = record.meta.specificAuth.split(',');
        for (const specificAuthItem of specificAuthArray) {
          if (storedUser[specificAuthItem]) {
            specificAuthFound = true;
            break;
          }
        }
        if (specificAuthFound) {
          next();
        }
        // Leadent or super user so allow access
        else if (storedUser.leadent || storedUser.superUser) {
          next();
        } else {
          // We're logged in and the target route DOES require authorisation
          // and there's a specific level of auth required
          // and the user DOES NOT have the level of auth required
          // so send to the dashboard
          next('dashboard');
        }
      } else {
        next();
      }
    });
  }
});

export default router;
