import axios from 'axios';
import configData from '../config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.msgStats.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }
    throw new Error('No access token Found');
  },
  (err) => Promise.reject(err),
);

export const getRollupStats = (dateFrom = null, dateTo = null) => {
  const params = new URLSearchParams();
  if (dateFrom && dateTo) {
    params.append('dateFrom', dateFrom);
    params.append('dateTo', dateTo);
  }
  const options = {
    method: 'GET',
    params,
    url: configData.msgStats.rollupStatsUrl,
  };
  return apiClient(options);
};

export const getFailedMessages = (dateFrom = null, dateTo = null) => {
  const params = new URLSearchParams();
  if (dateFrom && dateTo) {
    params.append('dateFrom', dateFrom);
    params.append('dateTo', dateTo);
  }
  const options = {
    method: 'GET',
    params,
    url: configData.msgStats.failedMessagesUrl,
  };
  return apiClient(options);
};

export const getDeliveredHitCount = () => {
  const options = {
    method: 'GET',
    url: configData.msgStats.deliveredHitCount,
  };
  return apiClient(options);
};

export const getHitCount = () => {
  const options = {
    method: 'GET',
    url: configData.msgStats.hitCount,
  };
  return apiClient(options);
};

export const getHitCountByDay = () => {
  const options = {
    method: 'GET',
    url: configData.msgStats.hitCountByDay,
  };
  return apiClient(options);
};

export const getOmwClickCounts = (daysOfHistory) => {
  const params = new URLSearchParams();
  params.append('daysOfHistory', daysOfHistory);

  const options = {
    method: 'GET',
    url: configData.msgStats.hitCount,
    params,
  };
  return apiClient(options);
};

export const getMonthlyBreakdown = (daysOfHistory) => {
  const params = new URLSearchParams();
  params.append('daysOfHistory', daysOfHistory);

  const options = {
    method: 'GET',
    url: configData.msgStats.monthlyBreakdown,
    params,
  };
  return apiClient(options);
};
