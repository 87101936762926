export default {
  namespaced: true,
  state: {
    searchCriteria: {},
    coreLoaded: false,
    serviceLoaded: false,
    uiLoaded: false,
    eventsLoaded: false,
    uiCssLoaded: false,
  },

  mutations: {
    SET_SEARCH_CRITERIA: (state, payload) => {
      state.searchCriteria = payload;
    },
    SET_CORE_LOADED: (state, payload) => {
      state.coreLoaded = payload;
    },
    SET_SERVICE_LOADED: (state, payload) => {
      state.serviceLoaded = payload;
    },
    SET_UI_LOADED: (state, payload) => {
      state.uiLoaded = payload;
    },
    SET_EVENTS_LOADED: (state, payload) => {
      state.eventsLoaded = payload;
    },
    SET_UI_CSS_LOADED: (state, payload) => {
      state.uiCssLoaded = payload;
    },
  },
  actions: {
    setSearchCriteria({ commit }, payload) {
      commit('SET_SEARCH_CRITERIA', payload);
    },
    setInitialState({ commit }) {
      commit('SET_SEARCH_CRITERIA', {});
    },
  },
  getters: {
    searchCriteria: (state) => state.searchCriteria,
    coreLoaded: (state) => state.coreLoaded,
    serviceLoaded: (state) => state.serviceLoaded,
    uiLoaded: (state) => state.uiLoaded,
    eventsLoaded: (state) => state.eventsLoaded,
    uiCssLoaded: (state) => state.uiCssLoaded,
    allLoaded: (state) =>
      state.coreLoaded &&
      state.serviceLoaded &&
      state.uiLoaded &&
      state.eventsLoaded &&
      state.uiCssLoaded,
  },
};
