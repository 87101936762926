export default {
  namespaced: true,
  state: {
    resourceRoutes: [],
  },
  mutations: {
    SET_RESOURCE_ROUTES: (state, resourceRoutes) => {
      resourceRoutes.forEach((resource) => {
        state.resourceRoutes.push(resource);
      });
    },
    DISCARD_RESOURCE_ROUTES: (state) => {
      while (state.resourceRoutes.length > 0) {
        state.resourceRoutes.pop();
      }
    },
  },
  actions: {
    storeResourceRoutes: ({ commit }, resourceRoutes) => {
      commit('DISCARD_RESOURCE_ROUTES');
      commit('SET_RESOURCE_ROUTES', resourceRoutes);
    },
    discardResourceRoutes: ({ commit }) => {
      commit('DISCARD_RESOURCE_ROUTES');
    },
  },
  getters: {
    resourceRoutes: (state) => state.resourceRoutes,
  },
};
