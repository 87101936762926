<!--
  Component that allows users to reset their password
-->
<template>
  <auth-container>
    <template #authForm>
      <validation-observer v-slot="{ invalid }" ref="passValObs">
        <form @submit.prevent="">
          <b-field
            label="We'll send a password reset link to this email address"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="passResetValProv"
              name="Email"
              rules="required|email"
            >
              <b-input
                v-model="email"
                name="email"
                @input="clearMessage"
              ></b-input>
              <span class="help is-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-field>
          <b-field label="Request password reset link">
            <b-button
              :disabled="invalid"
              type="is-primary"
              @click="sendPasswordResetEmail"
            >
              Reset password
            </b-button>
          </b-field>
          <p v-if="error">
            {{ error }}
          </p>
          <p v-else-if="submitted">
            {{ message }}
          </p>
        </form>
      </validation-observer>
    </template>
  </auth-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import AuthContainer from './AuthContainer';

extend('email', {
  ...email,
  message: 'Please enter a valid email address',
});
extend('required', {
  ...required,
  message: 'Email is required',
});

setInteractionMode('aggressive');

export default defineComponent({
  components: {
    AuthContainer,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: null,
      error: null,
      submitted: false,
    };
  },
  computed: {
    message() {
      return (
        this.$t('If') +
        ' ' +
        this.email +
        ' ' +
        this.$t(
          `is a registered email you'll receive a message with a reset link shortly`,
        )
      );
    },
  },
  methods: {
    clearMessage() {
      this.submitted = false;
    },
    async sendPasswordResetEmail() {
      try {
        const auth = getAuth(this.$firebase);
        sendPasswordResetEmail(auth, this.email, {
          url: this.$configData.auth.signin.url,
        });
      } catch (err) {
        if (err.code === 'auth/user-not-found') {
          this.error =
            this.$t(
              `Sorry, there's no record of a user with the email address`,
            ) +
            ': ' +
            this.email;
          return err;
        }
      } finally {
        this.submitted = true;
      }
    },
  },
});
</script>
