export default {
  namespaced: true,
  state: {
    searchParams: {
      time_Date_gte: null,
      time_Date_lte: null,
    },
    exportTemplates: [],
    exportTemplateIndex: -1,
    exportColumns: [],
    exportTemplateName: null,
  },

  mutations: {
    SET_SEARCH_PARAMS: (state, payload) => {
      state.searchParams = payload;
    },
    SET_EXPORT_TEMPLATES: (state, payload) => {
      state.exportTemplates = payload;
    },
    ADD_EXPORT_TEMPLATE: (state, payload) => {
      state.exportTemplates.push(payload);
    },
    UPDATE_EXPORT_TEMPLATE: (state, payload) => {
      state.exportTemplates[payload.id] = payload.exportTemplate;
    },
    DELETE_EXPORT_TEMPLATE: (state, payload) => {
      state.exportTemplates.splice(payload, 1);
    },
    SET_EXPORT_TEMPLATE_INDEX: (state, payload) => {
      state.exportTemplateIndex = payload;
    },
    SET_EXPORT_COLUMNS: (state, payload) => {
      state.exportColumns = payload;
    },
    UNSHIFT_EXPORT_COLUMN: (state, payload) => {
      state.exportColumns.unshift(payload);
    },
    SPLICE_EXPORT_COLUMN: (state, payload) => {
      state.exportColumns.splice(payload, 1);
    },
    MOVE_EXPORT_COLUMN: (state, payload) => {
      const moveRow = state.exportColumns[payload.draggingRowIndex];
      state.exportColumns.splice(payload.draggingRowIndex, 1);
      state.exportColumns.splice(payload.droppedOnRowIndex, 0, moveRow);
    },
    ENABLE_EXPORT_COLUMN: (state, payload) => {
      state.exportColumns[payload.index].enabled = payload.enabled;
    },
    SET_EXPORT_TEMPLATE_NAME: (state, payload) => {
      state.exportTemplateName = payload;
    },
  },
  actions: {
    setSearchParams({ commit }, payload) {
      commit('SET_SEARCH_PARAMS', payload);
    },
    setExportTemplates({ commit }, payload) {
      commit('SET_EXPORT_TEMPLATES', payload);
    },
    addExportTemplate({ commit }, payload) {
      commit('ADD_EXPORT_TEMPLATE', payload);
    },
    updateExportTemplate({ commit }, payload) {
      commit('UPDATE_EXPORT_TEMPLATE', payload);
    },
    deleteExportTemplate({ commit }, payload) {
      commit('DELETE_EXPORT_TEMPLATE', payload);
    },
    setExportTemplateIndex({ commit }, payload) {
      commit('SET_EXPORT_TEMPLATE_INDEX', payload);
    },
    setExportColumns({ commit }, payload) {
      commit('SET_EXPORT_COLUMNS', payload);
    },
    unshiftExportColumn({ commit }, payload) {
      commit('UNSHIFT_EXPORT_COLUMN', payload);
    },
    spliceExportColumn({ commit }, payload) {
      commit('SPLICE_EXPORT_COLUMN', payload);
    },
    moveExportColumn({ commit }, payload) {
      commit('MOVE_EXPORT_COLUMN', payload);
    },
    enableExportColumn({ commit }, payload) {
      commit('ENABLE_EXPORT_COLUMN', payload);
    },
    setExportTemplateName({ commit }, payload) {
      commit('SET_EXPORT_TEMPLATE_NAME', payload);
    },
  },
  getters: {
    searchParams: (state) => state.searchParams,
    exportTemplateIndex: (state) => state.exportTemplateIndex,
    exportColumns: (state) => state.exportColumns,
    exportTemplateName: (state) => state.exportTemplateName,
    exportTemplates: (state) => state.exportTemplates,
  },
};
