<!--
  Main welcome screen
-->
<template>
  <section>
    <div class="container has-text-centered">
      <div class="hero-body">
        <div class="columns is-vcentered welcome-box has-background-white">
          <div class="column is-12">
            <span class="is-size-1 has-text-primary has-text-weight-bold">
              {{ $t('Leadent Digital') }}
            </span>
            <p class="has-text-primary is-size-3">
              {{ $t('Field Service Portal') }}
            </p>
            <div>
              <div
                class="buttons"
                style="justify-content: center; margin-top: 1em"
              >
                <b-button type="is-primary" @click="onSignup">
                  {{ $t('Sign Up') }}
                </b-button>

                <b-button type="is-info" @click="onLogin">
                  {{ $t('Login') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'WelcomePage',
  computed: {
    samlActive() {
      return this.$configData?.auth?.saml?.active;
    },
    oidcActive() {
      return this.$configData?.auth?.oidc?.active;
    },
  },
  methods: {
    ...mapActions('storeAuth', ['loginSaml', 'loginOidc']),
    onLogin() {
      if (this.oidcActive) {
        this.loginOidc();
      } else if (this.samlActive) {
        this.loginSaml();
      } else {
        this.$router.push('signin').catch(() => {
          /* noop */
        });
      }
    },
    onSignup() {
      this.$router.push('signup');
    },
  },
});
</script>
<style scoped>
.welcome-box {
  width: 500px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
}
</style>
