<script>
import i18n from '../lang/lang';

export default {
  name: 'I18nMixin',
  methods: {
    switchLang(lang) {
      if (lang in this.$i18n.messages) {
        this.$i18n.locale = lang;
      } else {
        this.loadLocaleMessage(lang, (err, message) => {
          if (err) {
            return;
          }
          i18n.setLocaleMessage(lang, message);
          this.$i18n.locale = lang;
        });
      }
    },
    setLang(lang) {
      this.$store.dispatch('storeLanguage/setLang', lang);
    },
    setLangNew(lang) {
      this.$store.dispatch('storeLanguage/setLangNew', lang);
    },
    loadLocaleMessage(locale, cb) {
      return fetch(`./src/lang/${locale}.json`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (Object.keys(json).length === 0) {
            return Promise.reject(new Error('locale empty !!'));
          } else {
            return Promise.resolve(json);
          }
        })
        .then((message) => {
          cb(null, message);
        })
        .catch((error) => {
          cb(error);
        });
    },
  },
};
</script>
